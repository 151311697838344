tpl-form-field {
  display: block;
  & + tpl-form-field {
    margin-top: 16px;
  }

  .form-label {
    margin-bottom: 8px;
  }
  .form-note {
    margin-top: 8px;
    &:empty {
      display: none;
    }
  }
  .form-value {
    font-size: 1.6rem;
    line-height: 22px;
  }
  .input-group {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }

  .form-input {
    position: relative;
    width: 100%;
    input,
    textarea,
    > div:not(.form-value, .input-group) {
      background-color: var(--tpl-color-form-background);
      border: 1px solid var(--tpl-color-border);
      border-radius: 4px;
      box-sizing: border-box;
      font-size: 15px;
      line-height: 1.8;
      outline: none;
      padding: 8px 16px;
      transition: 0.2s;
      width: 100%;
      &:focus {
        border-color: var(--tpl-color-primary);
      }
    }
    textarea {
      line-height: 1.6;
      resize: none;
    }
  }
}
