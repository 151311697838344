tpl-form-group {
  display: block;
  & + tpl-form-group {
    margin-top: 32px;
  }

  .form-group-label {
    tpl-form-label {
      --font-size: 1.7rem;
      --font-weight: 600;
      --color: var(--tpl-color-text);
    }
  }

  .form-group-container {
    margin-top: 16px;
  }
}
